import {
    Image,
    Col,
    Row
} from 'react-bootstrap'

import PolsourceCaseStudy from '../assets/6_Polsource Case Study@3x.png';

export default function Wave(){
    return(
        <>
            <Row className="d-sm-none bg-white w-100 mx-auto justify-content-center">
                <Col className="mx-auto mt-xl-5 text-center text-md-start" md={6} xl={7}>
                    
                    <h1 className="text-dark-blue fs-1">
                        What does<br></br>
                        Success look like?
                    </h1>
                    <p className="lead">
                    See for yourself and learn more about<br></br>
                    how EPAM PolSource and Salesforce<br></br>
                    help organizations transform in our<br></br>
                    latest case study: Evolving the Customer<br></br>
                    Experience for One of the World's<br></br>
                    Largest Staffing Agencies<br></br>
                    <a className="ms-1" href="https://polsource.com/wp-content/uploads/2021/05/EPAM-PolSource-Staffing-Agency-Success-Story.pdf">[Read Now]</a>
                    </p>
                </Col>
                <Col md={6} xl={5} className="pb-md-5 pb-lg-0 pb-xl-5">
                    <Image
                        className="polsource-card img-fluid pb-md-5 pb-xl-0 contrast-image"
                        src={PolsourceCaseStudy}
                    ></Image>
                </Col>
            </Row>
            <div className="d-none d-sm-flex white-wave">
                <Col xs={11} lg={10} className="mx-auto wrapper pt-lg-5">
                    <Row className="w-100 mx-auto justify-content-center">
                        <Col className="mx-auto mt-xl-5 text-center text-lg-start" lg={6}>
                            <Col className="mx-auto mx-lg-0 ms-lg-auto col-xxl-9 col-xl-10 col-lg-12 col-md-11">
                                <h1 className="text-dark-blue fs-1">
                                    What does<br></br>
                                    Success look like?
                                </h1>
                                <p className="lead">
                                    See for yourself and learn more about<br></br>
                                    how EPAM PolSource and Salesforce<br></br>
                                    help organizations transform in our<br></br>
                                    latest case study: Evolving the Customer<br></br>
                                    Experience for One of the World's<br></br>
                                    Largest Staffing Agencies 
                                    <a className="ms-1" href="https://polsource.com/wp-content/uploads/2021/05/EPAM-PolSource-Staffing-Agency-Success-Story.pdf" target="blank">[Read Now]</a>
                                </p>
                            </Col>
                        </Col>
                        <Col lg={6} className="pb-md-5 pb-lg-0 pb-xl-5">
                            <Col className="mx-auto me-lg-auto col-lg-12 col-xl-11 col-md-6">
                                <Image
                                    className="polsource-card img-fluid pb-md-5 pb-xl-0 contrast-image"
                                    src={PolsourceCaseStudy}
                                ></Image>
                            </Col>
                        </Col>
                    </Row>
                </Col>
            </div>
        </>
    )

}