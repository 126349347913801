import React, { useRef } from 'react';
import {
    Col
} from 'react-bootstrap';
//https://brainhubeu.github.io/react-carousel/docs/examples/multipleSlides
import Carousel, { slidesToShowPlugin, autoplayPlugin } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';


export default function CarouselDisplay(){
    const LeftRef = useRef(null);
    const MiddleRef = useRef(null);
    const RightRef = useRef(null);


    function LeftStat(){
        return(
            <Col id="leftCol" ref={LeftRef} className="carousel-column my-auto text-center text-white">
                <h1 className="SalesforceSans-Bold">
                    84%
                </h1>
                 <p className="SalesforceSans-Light mb-0">
                    of customers say the experience a<br></br>
                    company provides is as important<br></br>
                    as its products and services<br></br>
                    (Salesforce)
                </p>
            </Col>
        )
    }

    function MiddleStat(){
        return(
            <Col id="middleCol" ref={MiddleRef} className="carousel-column my-auto text-center text-white">
                <p className="SalesforceSans-Light mb-0">
                    Experience Driven<br></br> Businesses see over
                </p>
                <h1 className="SalesforceSans-Bold">
                    1.5X
                </h1>
                 <p className="SalesforceSans-Light mb-0">
                    higher YoY growth than other<br></br> 
                    companies in customer retention,<br></br>
                    repeat purchase rates, and <br></br> 
                    customer lifetime value. <br></br>
                    (Forrester)
                </p>
            </Col>
        )
    }

    function RightStat(){
        return(
            <Col id="rightCol" ref={RightRef} className="carousel-column my-auto text-center text-white">
                <p className="SalesforceSans-Light mb-0">
                    Companies with a highly engaged<br></br> workforce have
                </p>
                <h1 className="SalesforceSans-Bold">
                    21%
                </h1>
                 <p className="SalesforceSans-Light mb-0">
                    higher profitability<br></br>(Gallup)
                </p>
            </Col>
        )
    }


    return(
        <>
        <Col id="lg-carousel" md={12} xl={10} className="mx-auto px-3">
            <Carousel
                draggable={false}
                plugins={[
                    'infinite',
                    'arrows',
                    'centered',
                    {
                    resolve: autoplayPlugin,
                        options: {
                            interval: 7000,
                        }
                    },
                    {
                    resolve: slidesToShowPlugin,
                        options: {
                        numberOfSlides: 3
                        }
                    }
                ]}
                animationSpeed={1500}
            >
                <MiddleStat></MiddleStat>
                <LeftStat></LeftStat>
                <RightStat></RightStat>
            </Carousel>
        </Col>
        <Col id="sm-carousel" className="mx-auto px-2">
            <Carousel
                draggable={false}
                plugins={[
                    'centered',
                    'infinite',
                    'arrows',
                    {
                    resolve: autoplayPlugin,
                        options: {
                            interval: 8000,
                        }
                    },
                    {
                    resolve: slidesToShowPlugin,
                        options: {
                        numberOfSlides: 1
                        }
                    }
                ]}
                animationSpeed={1500}
            >
                <LeftStat></LeftStat>
                <MiddleStat></MiddleStat>
                <RightStat></RightStat>
            </Carousel>
        </Col>
        </>
    )
}

