import {
    Image
} from 'react-bootstrap';
import Mountain from '../assets/Mountain@3x.png';
import MountainWide from '../assets/Mountain@3x-ultrawide.png';
import Waving from '../assets/Gifs/Waving.gif';


export default function MountainWaving(){

    return(
        <>
            <Image
                src={Mountain}
                className="d-xl-none w-100 px-0 mx-0"
            ></Image>
            <Image
                src={MountainWide}
                className="d-none d-xl-flex w-100 px-0 mx-0 mountain-wide"
                style={{maxHeight: 775}}
            ></Image>
            <div className="position-relative wrapper">
                <div className="waving-wrapper">
                    <Image
                        src={Waving}
                        className="waving"
                        style={{zIndex: 0}}
                    ></Image>
                </div>
            </div>
        </>
    )
}