import {
    Image,
    Col
} from 'react-bootstrap'

import CampfireImg from '../assets/Gifs/Campfire.gif';

export default function Campfire(){
    return(
        <>
            <div className="forest d-flex flex-column flex-grow-1">
                <Col xs={10} md={10} xl={6} className="h-100 text-white mx-auto text-center">
                    <p className="SalesforceSans-Light lead fs-2 pt-0 pt-xl-0">
                    To find out how partnering with EPAM PolSource and Salesforce can make your customers and employees happy visit <a className="SalesforceSans-Bold text-white" href="https://polsource.com/contact/?utm-source=polSource&utm-medium=email&utm-campaign=salesforce-door-opener" target="blank">polsource.com</a> or check out the <span className="SalesforceSans-Bold"><a className="SalesforceSans-Bold text-white" href="https://appexchange.salesforce.com/appxConsultingListingDetail?listingId=a0N3A00000E8VKbUAN" target="blank">EPAM PolSource profile on AppExchange</a>.</span>
                    </p>
                    <Col xs={12} sm={12} className="mx-auto campfire">
                         <Image
                            src={CampfireImg}
                            className="img-fluid"
                        ></Image>
                    </Col>
                </Col>
            </div>
        </>
    )

}