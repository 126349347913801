import React, { useState, useEffect } from 'react';
import axios from 'axios';
//Import our custom bootstrap implementation
import './scss/custom.scss';
import {
  Row,
  Container,
  Image,
  Button
} from 'react-bootstrap';
//https://www.npmjs.com/package/validator
import validator from 'validator';
//https://www.npmjs.com/package/lottie-react
import Lottie from "lottie-react";
//Animation file for loader
import LoaderJSON from './assets/loader.json';

import Astronaut from './assets/Astronaut-big.png';


/* Content Imports */
import LogoRow from './components/LogoRow.js';
import TitleRow from './components/TitleRow.js';
import AssessmentBlock from './components/AssessmentBlock.js';
import Carousel from './components/Carousel.js';
import MountainWaving from './components/MountainWaving.js';
import ExperienceBlock from './components/ExperienceBlock.js';
import Success from './components/Success.js';
import Campfire from './components/Campfire.js';

function App() {
  //https://reactjs.org/docs/hooks-reference.html#usestate
  const [uniqueId, setUniqueId] = useState(),
    [isValidCode, setValidCode] = useState(null);

  //Set param on mount
  //https://reactjs.org/docs/hooks-reference.html#useeffect
  useEffect(() => {
    //Set window params
    const urlParams = new URLSearchParams(window.location.search);
    //Get the unique page parameter
    const code = urlParams.get('code');
    if (code && code.length > 0) {
      if (validator.isUUID(code)) {
        //LA: PartnerLP-Prospects
        axios.post('https://prod-126.westus.logic.azure.com:443/workflows/e852503099db40cda2fc39048000afbc/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=JPHdzNOl99B0S4u4YA3FeqtWjY_IXav6cqtr18mSBAk',{
          code:code
        }).then(() => {
          setUniqueId(code);
          setValidCode(true);
        })
        .catch(error => {
          console.error(error);
          setValidCode(false);
        })
      }
      else{
        setValidCode(false);
      }
    }
    else{
      setValidCode(false);
    }
  }, [uniqueId]);

  //Controls the main page content
  const mainContent = () => {
    //Initial state before code is validated
    if (isValidCode === null) {
      return (
          <Row className="w-100 mx-auto d-flex flex-grow-1 row">
            <Container className="my-auto mx-auto text-center">
              <Lottie
                className="main-loader"
                animationData={LoaderJSON}
              />
            </Container>
          </Row>
      )
    }
    //If the code param is valid show the main page
    else if (isValidCode === true) {
      return (
          <>
            <div className="bg-blue-gradient mb-0 pb-0">
              <div className="wrapper mx-auto">
                <LogoRow></LogoRow>
                <TitleRow></TitleRow>
                <AssessmentBlock uniqueId={uniqueId}></AssessmentBlock>
                <Carousel></Carousel>
              </div>          
              <MountainWaving></MountainWaving>
              <ExperienceBlock></ExperienceBlock>
              <Success></Success>
              <Campfire></Campfire>
            </div>
          </>
      )
    }
    //If the param is not valid show error
    else{
      return (
        <>
          <nav>
            <div className="container wrapper mx-auto">
              <img style={{ width: 180 }} alt="Appexchange Logo" className="p-1 img-fluid mt-3" src="https://appexchange.salesforce.com/resource/1618441898000/assetsappx/images/logo-appexchange.png" />
            </div>
          </nav>
          <div id="expired-guid" style={{overflowY: 'hidden'}} className="h-100 row flex-row-reverse w-100 mx-auto bg-dark-blue text-white d-flex flex-grow-1">
            <div className="col-7 col-md-7 d-flex flex-column">
                <div className="text-center my-auto">
                    <h1 className="display-1 fw-bold">Invalid Link</h1>
                    <div className="col-lg-6 mx-auto">
                        <p className="lead mb-4">
                            We're sorry, this link is invalid.
                        </p>
                        <Button
                          variant="salesforce-blue"
                          className="fw-bold text-white"
                          size="lg"
                          onClick={() => window.location.replace('https://appexchange.salesforce.com/')}
                        >Appexchage Home</Button>
                    </div>
                </div>
            </div>
            <div className="col-5 col-md-5 col-xxl-4 d-flex flex-column">
            <Image className="mt-auto d-none d-xl-flex img-fluid" alt="Astronaut" src={Astronaut}  />
            <Image className="my-auto d-xl-none img-fluid" alt="Astronaut" src={Astronaut}  />
            </div>
        </div>
      </>
      )
    }
  }

  return (
    <div className="d-flex flex-column h-100">
        {mainContent()}
    </div>
  )

}

export default App;
