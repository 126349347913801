import React, {useState, useRef, useEffect} from 'react';
import {
    Container,
    Button,
    Col,
    Row,
    Image,
    Form
} from 'react-bootstrap';
import anime from 'animejs';
import axios from 'axios';

import GiftImage from '../assets/3_Gift@3x.png';
import StronglyDisagree from '../assets/Strongly disagree@2x.png';
import Disagree from '../assets/Disagree@2x.png';
import Agree from '../assets/Agree@2x.png';
import StronglyAgree from '../assets/Strongly agree@2x.png';

import {ChevronRight, ChevronLeft} from 'react-bootstrap-icons';

export default function InteractiveAssessment(props){

    //https://reactjs.org/docs/hooks-reference.html#useref
    const CurrentStep = useRef(null);
    //https://reactjs.org/docs/hooks-reference.html#usestate
    //Set the beginning state
    const [stepState , setStepState] = useState(0),
        [questionAnswers, setQuestionAnswers] = useState({});

    // Figure out which effect dependency changed: 
    // https://stackoverflow.com/questions/55187563/determine-which-dependency-array-variable-caused-useeffect-hook-to-fire
    const useEffectDebugger = (effectHook, dependencies, dependencyNames = []) => {
        const previousDeps = usePrevious(dependencies, []);
        
        const changedDeps = dependencies.reduce((accum, dependency, index) => {
            if (dependency !== previousDeps[index]) {
            const keyName = dependencyNames[index] || index;
            return {
                ...accum,
                [keyName]: {
                before: previousDeps[index],
                after: dependency
                }
            };
            }
        
            return accum;
        }, {});
        //If dependecies (answers) changed update table storage
        if (Object.keys(changedDeps).length) {
            // Question ids are 1 greater than the index of dependencies within useEffectDebugger
            let questionId = ++ Object.keys(changedDeps)[0];
            axios.post('https://prod-32.westus.logic.azure.com:443/workflows/7460a7221701454b876872a8340ce6f5/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=et_2tMP94V5HuzawNuCz4Tf2uy43JtGFn2KNSDTiuhA',{
                uniqueId:props.uniqueId,
                question: {
                    name: 'Question'+questionId,
                    answer: changedDeps[Object.keys(changedDeps)[0]].after
                }
            })
            .catch(error => {
                console.error(error);
            })
    }
        // eslint-disable-next-line
        useEffect(effectHook, dependencies);
    };

    // Used in effect debugger
    const usePrevious = (value, initialValue) => {
    const ref = useRef(initialValue);
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
    };

    // Alternate to useEffect that debugs the changed effect, see SO link on line 31
    useEffectDebugger(() => {
    }, [
        questionAnswers.Question1,
        questionAnswers.Question2,
        questionAnswers.Question3,
        questionAnswers.Question4,
        questionAnswers.Question5
    ])

    //Checks state and returns the appropriate component
    const StepController = () => {
        if(stepState === 0){
            return(
                AssessmentPromo()
            )
        }
        else if(stepState === 1){
              return(
                <Question1 currentAnswers={questionAnswers} setQuestionAnswers={setQuestionAnswers} AnimateStepChangeForward={AnimateStepChangeForward}></Question1>
            )
        }
        else if(stepState === 2){
            return(
                <Question2 currentAnswers={questionAnswers} setQuestionAnswers={setQuestionAnswers} AnimateStepChangeForward={AnimateStepChangeForward}></Question2>
            )
        }
        else if(stepState === 3){
                return(
                <Question3 currentAnswers={questionAnswers} setQuestionAnswers={setQuestionAnswers} AnimateStepChangeForward={AnimateStepChangeForward}></Question3>
            )
        }
        else if(stepState === 4){
            return(
            <Question4 currentAnswers={questionAnswers} setQuestionAnswers={setQuestionAnswers} AnimateStepChangeForward={AnimateStepChangeForward}></Question4>
            )
        }
        else if(stepState === 5){
            return(
            <Question5 currentAnswers={questionAnswers} setQuestionAnswers={setQuestionAnswers} AnimateStepChangeForward={AnimateStepChangeForward}></Question5>
            )
        }
        else if(stepState === 6){
            return(
                <RedeemGift></RedeemGift>
            )
        }
    }
    
    //Assessment intro screen
    const AssessmentPromo = () => {
        return(
            <div ref={CurrentStep}>
                <Row className="w-100 mx-auto pb-4">
                    <Col className="mx-auto col-11 col-md-10 col-lg-8 col-xl-6">
                        <h2 className="text-center text-white SalesforceSans-Bold">
                            Take a 3 Minute assessment to benchmark your current customer experience.
                        </h2>
                    </Col>
                </Row>
                <Col className="mx-auto justify-content-center">
                    <Row className="justify-content-center w-100 mx-auto">
                        <Col lg={6} className="mx-auto">
                            <Row className="justify-content-center justify-content-lg-end mt-4">
                                <Col xs={9}>
                                <p className="text-white SalesforceSans-Light fs-4">
                                    Today's customers judge companies based on their experience as a whole-and they expect consistency and personalization across every interaction.
                                </p>
                                <p className="text-white">
                                    <span className="SalesforceSans-Light fs-4">
                                        Take the 5 question assessment to get an idea of how well your company is meeting those needs, and
                                    </span>
                                    <span className="fs-4 ms-2 SalesforceSans-Bold">
                                        get a free gift that makes you happy and lets you know how much we appreciate your time!
                                    </span>
                                </p>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={6}>
                            <Row className="justify-content-center justify-content-lg-start">
                                <Col className="text-center text-lg-start ms-md-3">
                                    <Button 
                                    onClick={() =>  {
                                        AnimateStepChangeForward();
                                        axios.post('https://prod-32.westus.logic.azure.com:443/workflows/7460a7221701454b876872a8340ce6f5/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=et_2tMP94V5HuzawNuCz4Tf2uy43JtGFn2KNSDTiuhA',{
                                            uniqueId:props.uniqueId,
                                            question: {
                                                name: 'StartedAssessment',
                                                answer: true
                                            }
                                        })
                                        .catch(error => {
                                            console.error(error);
                                        })
                                    }} 
                                    style={{borderRadius: '0.6rem', letterSpacing: 2}}
                                    size="lg"
                                    className="contrast-btn fs-4 my-4 ms-md-5 py-3 px-4 fw-bold mx-auto text-white"
                                    variant="salesforce-blue"
                                    >
                                        Claim Your Gift
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Image 
                                    style={{maxHeight:350, maxWidth:375}} 
                                    className="img-fluid mx-auto ms-lg-5 py-4 contrast-image" 
                                    src={GiftImage}
                                ></Image>
                            </Row>
                            <Row>
                                <Col xs={12} lg={8} className="text-center">
                                    <p className="text-white">Claim your thank you gift</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>          
            </div>
        )
    }

    //Survey questions display prompts, collect answers, request animation, and send answers to parent question answers state
    class Question1 extends React.Component{

        constructor(props) {
            super(props);
            this.state = {
                activeCircle: null,
                activeButton: false,
                activeCard: null
            };  
        }

        onCardClick = (e) => {
            if(this.state.activeCard){
                this.state.activeCard.classList.toggle('active-card');
            }
            let card = e.currentTarget;
            this.setState({activeCard: card});
            card.classList.toggle('active-card');
            if(this.state.activeButton === false){
                this.setState({activeButton: true})
            }
        }

        onQuestionSubmit = () => {
            let newAnswers = {};
            newAnswers = this.props.currentAnswers;
            newAnswers['Question'+stepState] = this.state.activeCard.dataset.value;
            this.props.setQuestionAnswers(newAnswers);
            this.props.AnimateStepChangeForward()
        }

        render(props){
            return(
                //step-hidden
                <div className="assessment-step" ref={CurrentStep}>
                    <div className="text-center">
                        <Col xs={10} lg={6} className="mx-auto">
                            <p className="text-salesforce-blue mb-4" style={{letterSpacing: 2}}>
                                {'QUESTION '+stepState+'/5'}
                            </p>
                            <p className="lead SalesforceSans-Bold text-white">
                                Despite recent changes in the business environment, we have been able to foster positive and meaningful customer relationships.
                            </p>
                        </Col>
                        <Col className="mx-auto" lg={8}>
                            <div className="px-3 row row-cols-2 row-cols-lg-4 align-items-stretch g-1 py-4">
                                <div className="col my-2">
                                    <div data-value="Strongly Disagree" onClick={this.onCardClick} className="card card-cover survey-card h-100 overflow-hidden text-white border-3 border-white-opaque mx-2" style={{borderRadius: '0.8rem'}}>
                                    <div className="d-flex flex-column h-100 px-3 pb-2 pt-4 text-white text-shadow-1">
                                        <Image style={{width: '50%', maxWidth: 100, maxHeight: 100}} className="mx-auto" src={StronglyDisagree}></Image>
                                        <h5 className="mt-3">Strongly<br></br>Disagree</h5>
                                    </div>
                                    </div>
                                </div>
                                <div className="col my-2">
                                    <div data-value="Disagree" onClick={this.onCardClick} className="card card-cover survey-card h-100 overflow-hidden text-white border-3 border-white-opaque mx-2" style={{borderRadius: '0.8rem'}}>
                                    <div className="d-flex flex-column h-100 px-3 pb-2 pt-4 text-white text-shadow-1">
                                        <Image style={{width: '50%', maxWidth: 100, maxHeight: 100}} className="mx-auto" src={Disagree}></Image>
                                        <h5 className="mt-4">Disagree</h5>
                                    </div>
                                    </div>
                                </div>
                                <div className="col my-2">
                                    <div data-value="Agree" onClick={this.onCardClick} className="card card-cover survey-card h-100 overflow-hidden text-white border-3 border-white-opaque mx-2" style={{borderRadius: '0.8rem'}}>
                                    <div className="d-flex flex-column h-100 px-3 pb-2 pt-4 text-white text-shadow-1">
                                        <Image style={{width: '50%', maxWidth: 100, maxHeight: 100}} className="mx-auto" src={Agree}></Image>
                                        <h5 className="mt-4">Agree</h5>
                                    </div>
                                    </div>
                                </div>
                                <div className="col my-2">
                                    <div data-value="Strongly Agree" onClick={this.onCardClick} className="card card-cover survey-card h-100 overflow-hidden text-white border-3 border-white-opaque mx-2" style={{borderRadius: '0.8rem'}}>
                                    <div className="d-flex flex-column h-100 px-3 pb-2 pt-4 text-white text-shadow-1">
                                        <Image style={{width: '50%', maxWidth: 100, maxHeight: 100}} className="mx-auto" src={StronglyAgree}></Image>
                                        <h5 className="mt-3">Strongly<br></br>Agree</h5>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Button 
                            onClick={this.onQuestionSubmit}
                            disabled={!this.state.activeButton}
                            size="lg"
                            className="my-4 px-5 fw-bold mx-1 text-dark-blue bg-white border-3"
                        >
                            Next <ChevronRight> </ChevronRight>
                        </Button>
                    </div>
                </div>
            )
        }
    }

    class Question2 extends React.Component{

        constructor(props) {
            super(props);
            this.state = {
                activeCircle: null,
                activeButton: false,
                activeCard: null
            };  
        }

        onCardClick = (e) => {
            if(this.state.activeCard){
                this.state.activeCard.classList.toggle('active-card');
            }
            let card = e.currentTarget;
            this.setState({activeCard: card});
            card.classList.toggle('active-card');
            if(this.state.activeButton === false){
                this.setState({activeButton: true})
            }
        }

        onQuestionSubmit = () => {
            let newAnswers = {};
            newAnswers = this.props.currentAnswers;
            newAnswers['Question'+stepState] = this.state.activeCard.dataset.value;
            this.props.setQuestionAnswers(newAnswers);
            this.props.AnimateStepChangeForward()
        }

        render(props){
            return(
                //step-hidden
                <div className="assessment-step" ref={CurrentStep}>
                    <div className="text-center">
                        <Col xs={10} lg={6} className="mx-auto">
                            <p className="text-salesforce-blue mb-4" style={{letterSpacing: 2}}>
                                {'QUESTION '+stepState+'/5'}
                            </p>
                            <p className="lead SalesforceSans-Bold text-white">
                                Our customers would say we are easy to do business with and able to adapt to their changing needs.
                            </p>
                        </Col>
                        <Col className="mx-auto" lg={8}>
                            <div className="px-3 row row-cols-2 row-cols-lg-4 align-items-stretch g-1 py-4">
                                <div className="col my-2">
                                    <div data-value="Strongly Disagree" onClick={this.onCardClick} className="card card-cover survey-card h-100 overflow-hidden text-white border-3 border-white-opaque mx-2" style={{borderRadius: '0.8rem'}}>
                                    <div className="d-flex flex-column h-100 px-3 pb-2 pt-4 text-white text-shadow-1">
                                        <Image style={{width: '50%', maxWidth: 100, maxHeight: 100}} className="mx-auto" src={StronglyDisagree}></Image>
                                        <h5 className="mt-3">Strongly<br></br>Disagree</h5>
                                    </div>
                                    </div>
                                </div>
                                <div className="col my-2">
                                    <div data-value="Disagree" onClick={this.onCardClick} className="card card-cover survey-card h-100 overflow-hidden text-white border-3 border-white-opaque mx-2" style={{borderRadius: '0.8rem'}}>
                                    <div className="d-flex flex-column h-100 px-3 pb-2 pt-4 text-white text-shadow-1">
                                        <Image style={{width: '50%', maxWidth: 100, maxHeight: 100}} className="mx-auto" src={Disagree}></Image>
                                        <h5 className="mt-4">Disagree</h5>
                                    </div>
                                    </div>
                                </div>
                                <div className="col my-2">
                                    <div data-value="Agree" onClick={this.onCardClick} className="card card-cover survey-card h-100 overflow-hidden text-white border-3 border-white-opaque mx-2" style={{borderRadius: '0.8rem'}}>
                                    <div className="d-flex flex-column h-100 px-3 pb-2 pt-4 text-white text-shadow-1">
                                        <Image style={{width: '50%', maxWidth: 100, maxHeight: 100}} className="mx-auto" src={Agree}></Image>
                                        <h5 className="mt-4">Agree</h5>
                                    </div>
                                    </div>
                                </div>
                                <div className="col my-2">
                                    <div data-value="Strongly Agree" onClick={this.onCardClick} className="card card-cover survey-card h-100 overflow-hidden text-white border-3 border-white-opaque mx-2" style={{borderRadius: '0.8rem'}}>
                                    <div className="d-flex flex-column h-100 px-3 pb-2 pt-4 text-white text-shadow-1">
                                        <Image style={{width: '50%', maxWidth: 100, maxHeight: 100}} className="mx-auto" src={StronglyAgree}></Image>
                                        <h5 className="mt-3">Strongly<br></br>Agree</h5>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Button 
                            onClick={AnimateStepChangeBackward}
                            size="lg"
                            className="my-4 px-5 fw-bold mx-1 text-secondary bg-white border-0 rounded-0"
                            variant="light"
                        >
                            <ChevronLeft></ChevronLeft> Back
                        </Button>
                        <Button 
                            onClick={this.onQuestionSubmit}
                            disabled={!this.state.activeButton}
                            size="lg"
                            className="my-4 px-5 fw-bold mx-1 text-dark-blue bg-white border-3"
                        >
                            Next <ChevronRight> </ChevronRight>
                        </Button>
                    </div>
                </div>
            )
        }
    }

    class Question3 extends React.Component{

        constructor(props) {
            super(props);
            this.state = {
                activeCircle: null,
                activeButton: false,
                activeCard: null
            };  
        }

        onCardClick = (e) => {
            if(this.state.activeCard){
                this.state.activeCard.classList.toggle('active-card');
            }
            let card = e.currentTarget;
            this.setState({activeCard: card});
            card.classList.toggle('active-card');
            if(this.state.activeButton === false){
                this.setState({activeButton: true})
            }
        }

        onQuestionSubmit = () => {
            let newAnswers = {};
            newAnswers = this.props.currentAnswers;
            newAnswers['Question'+stepState] = this.state.activeCard.dataset.value;
            this.props.setQuestionAnswers(newAnswers);
            this.props.AnimateStepChangeForward()
        }

        render(props){
            return(
                //step-hidden
                <div className="assessment-step" ref={CurrentStep}>
                    <div className="text-center">
                        <Col xs={10} lg={6} className="mx-auto">
                            <p className="text-salesforce-blue mb-4" style={{letterSpacing: 2}}>
                                {'QUESTION '+stepState+'/5'}
                            </p>
                            <p className="lead SalesforceSans-Bold text-white">
                                Our overall customer experience helps to generate brand advocacy and referenceability for our company.
                            </p>
                        </Col>
                        <Col className="mx-auto" lg={8}>
                            <div className="px-3 row row-cols-2 row-cols-lg-4 align-items-stretch g-1 py-4">
                                <div className="col my-2">
                                    <div data-value="Strongly Disagree" onClick={this.onCardClick} className="card card-cover survey-card h-100 overflow-hidden text-white border-3 border-white-opaque mx-2" style={{borderRadius: '0.8rem'}}>
                                    <div className="d-flex flex-column h-100 px-3 pb-2 pt-4 text-white text-shadow-1">
                                        <Image style={{width: '50%', maxWidth: 100, maxHeight: 100}} className="mx-auto" src={StronglyDisagree}></Image>
                                        <h5 className="mt-3">Strongly<br></br>Disagree</h5>
                                    </div>
                                    </div>
                                </div>
                                <div className="col my-2">
                                    <div data-value="Disagree" onClick={this.onCardClick} className="card card-cover survey-card h-100 overflow-hidden text-white border-3 border-white-opaque mx-2" style={{borderRadius: '0.8rem'}}>
                                    <div className="d-flex flex-column h-100 px-3 pb-2 pt-4 text-white text-shadow-1">
                                        <Image style={{width: '50%', maxWidth: 100, maxHeight: 100}} className="mx-auto" src={Disagree}></Image>
                                        <h5 className="mt-4">Disagree</h5>
                                    </div>
                                    </div>
                                </div>
                                <div className="col my-2">
                                    <div data-value="Agree" onClick={this.onCardClick} className="card card-cover survey-card h-100 overflow-hidden text-white border-3 border-white-opaque mx-2" style={{borderRadius: '0.8rem'}}>
                                    <div className="d-flex flex-column h-100 px-3 pb-2 pt-4 text-white text-shadow-1">
                                        <Image style={{width: '50%', maxWidth: 100, maxHeight: 100}} className="mx-auto" src={Agree}></Image>
                                        <h5 className="mt-4">Agree</h5>
                                    </div>
                                    </div>
                                </div>
                                <div className="col my-2">
                                    <div data-value="Strongly Agree" onClick={this.onCardClick} className="card card-cover survey-card h-100 overflow-hidden text-white border-3 border-white-opaque mx-2" style={{borderRadius: '0.8rem'}}>
                                    <div className="d-flex flex-column h-100 px-3 pb-2 pt-4 text-white text-shadow-1">
                                        <Image style={{width: '50%', maxWidth: 100, maxHeight: 100}} className="mx-auto" src={StronglyAgree}></Image>
                                        <h5 className="mt-3">Strongly<br></br>Agree</h5>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Button 
                            onClick={AnimateStepChangeBackward}
                            size="lg"
                            className="my-4 px-5 fw-bold mx-1 text-secondary bg-white border-0 rounded-0"
                            variant="light"
                        >
                            <ChevronLeft></ChevronLeft> Back
                        </Button>
                        <Button 
                            onClick={this.onQuestionSubmit}
                            disabled={!this.state.activeButton}
                            size="lg"
                            className="my-4 px-5 fw-bold mx-1 text-dark-blue bg-white border-3"
                        >
                            Next <ChevronRight> </ChevronRight>
                        </Button>
                    </div>
                </div>
            )
        }
    }

    class Question4 extends React.Component{

        constructor(props) {
            super(props);
            this.state = {
                activeCircle: null,
                activeButton: false,
                activeCard: null
            };  
        }

        onCardClick = (e) => {
            if(this.state.activeCard){
                this.state.activeCard.classList.toggle('active-card');
            }
            let card = e.currentTarget;
            this.setState({activeCard: card});
            card.classList.toggle('active-card');
            if(this.state.activeButton === false){
                this.setState({activeButton: true})
            }
        }

        onQuestionSubmit = () => {
            let newAnswers = {};
            newAnswers = this.props.currentAnswers;
            newAnswers['Question'+stepState] = this.state.activeCard.dataset.value;
            this.props.setQuestionAnswers(newAnswers);
            this.props.AnimateStepChangeForward()
        }

        render(props){
            return(
                //step-hidden
                <div className="assessment-step" ref={CurrentStep}>
                    <div className="text-center">
                        <Col xs={10} lg={6} className="mx-auto">
                            <p className="text-salesforce-blue mb-4" style={{letterSpacing: 2}}>
                                {'QUESTION '+stepState+'/5'}
                            </p>
                            <p className="lead SalesforceSans-Bold text-white">
                                The technology we use makes it easy for employees to stay aligned internally, regardless of where they’re working.
                            </p>
                        </Col>
                        <Col className="mx-auto" lg={8}>
                            <div className="px-3 row row-cols-2 row-cols-lg-4 align-items-stretch g-1 py-4">
                                <div className="col my-2">
                                    <div data-value="Strongly Disagree" onClick={this.onCardClick} className="card card-cover survey-card h-100 overflow-hidden text-white border-3 border-white-opaque mx-2" style={{borderRadius: '0.8rem'}}>
                                    <div className="d-flex flex-column h-100 px-3 pb-2 pt-4 text-white text-shadow-1">
                                        <Image style={{width: '50%', maxWidth: 100, maxHeight: 100}} className="mx-auto" src={StronglyDisagree}></Image>
                                        <h5 className="mt-3">Strongly<br></br>Disagree</h5>
                                    </div>
                                    </div>
                                </div>
                                <div className="col my-2">
                                    <div data-value="Disagree" onClick={this.onCardClick} className="card card-cover survey-card h-100 overflow-hidden text-white border-3 border-white-opaque mx-2" style={{borderRadius: '0.8rem'}}>
                                    <div className="d-flex flex-column h-100 px-3 pb-2 pt-4 text-white text-shadow-1">
                                        <Image style={{width: '50%', maxWidth: 100, maxHeight: 100}} className="mx-auto" src={Disagree}></Image>
                                        <h5 className="mt-4">Disagree</h5>
                                    </div>
                                    </div>
                                </div>
                                <div className="col my-2">
                                    <div data-value="Agree" onClick={this.onCardClick} className="card card-cover survey-card h-100 overflow-hidden text-white border-3 border-white-opaque mx-2" style={{borderRadius: '0.8rem'}}>
                                    <div className="d-flex flex-column h-100 px-3 pb-2 pt-4 text-white text-shadow-1">
                                        <Image style={{width: '50%', maxWidth: 100, maxHeight: 100}} className="mx-auto" src={Agree}></Image>
                                        <h5 className="mt-4">Agree</h5>
                                    </div>
                                    </div>
                                </div>
                                <div className="col my-2">
                                    <div data-value="Strongly Agree" onClick={this.onCardClick} className="card card-cover survey-card h-100 overflow-hidden text-white border-3 border-white-opaque mx-2" style={{borderRadius: '0.8rem'}}>
                                    <div className="d-flex flex-column h-100 px-3 pb-2 pt-4 text-white text-shadow-1">
                                        <Image style={{width: '50%', maxWidth: 100, maxHeight: 100}} className="mx-auto" src={StronglyAgree}></Image>
                                        <h5 className="mt-3">Strongly<br></br>Agree</h5>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Button 
                            onClick={AnimateStepChangeBackward}
                            size="lg"
                            className="my-4 px-5 fw-bold mx-1 text-secondary bg-white border-0 rounded-0"
                            variant="light"
                        >
                            <ChevronLeft></ChevronLeft> Back
                        </Button>
                        <Button 
                            onClick={this.onQuestionSubmit}
                            disabled={!this.state.activeButton}
                            size="lg"
                            className="my-4 px-5 fw-bold mx-1 text-dark-blue bg-white border-3"
                        >
                            Next <ChevronRight> </ChevronRight>
                        </Button>
                    </div>
                </div>
            )
        }
    }

    class Question5 extends React.Component{

        constructor(props) {
            super(props);
            this.state = {
                activeCircle: null,
                activeButton: false,
                activeCard: null
            };  
        }

        onCardClick = (e) => {
            if(this.state.activeCard){
                this.state.activeCard.classList.toggle('active-card');
            }
            let card = e.currentTarget;
            this.setState({activeCard: card});
            card.classList.toggle('active-card');
            if(this.state.activeButton === false){
                this.setState({activeButton: true})
            }
        }

        onQuestionSubmit = () => {
            let newAnswers = {};
            newAnswers = this.props.currentAnswers;
            newAnswers['Question'+stepState] = this.state.activeCard.dataset.value;
            this.props.setQuestionAnswers(newAnswers);
            this.props.AnimateStepChangeForward()
        }

        render(props){
            return(
                //step-hidden
                <div className="assessment-step" ref={CurrentStep}>
                    <div className="text-center">
                        <Col xs={10} lg={6} className="mx-auto">
                            <p className="text-salesforce-blue mb-4" style={{letterSpacing: 2}}>
                                {'QUESTION '+stepState+'/5'}
                            </p>
                            <p className="lead SalesforceSans-Bold text-white">
                                We leverage Voice of the Customer and CSAT programs to capture and respond to changes in customer sentiment.
                            </p>
                        </Col>
                        <Col className="mx-auto" lg={8}>
                            <div className="px-3 row row-cols-2 row-cols-lg-4 align-items-stretch g-1 py-4">
                                <div className="col my-2">
                                    <div data-value="Strongly Disagree" onClick={this.onCardClick} className="card card-cover survey-card h-100 overflow-hidden text-white border-3 border-white-opaque mx-2" style={{borderRadius: '0.8rem'}}>
                                    <div className="d-flex flex-column h-100 px-3 pb-2 pt-4 text-white text-shadow-1">
                                        <Image style={{width: '50%', maxWidth: 100, maxHeight: 100}} className="mx-auto" src={StronglyDisagree}></Image>
                                        <h5 className="mt-3">Strongly<br></br>Disagree</h5>
                                    </div>
                                    </div>
                                </div>
                                <div className="col my-2">
                                    <div data-value="Disagree" onClick={this.onCardClick} className="card card-cover survey-card h-100 overflow-hidden text-white border-3 border-white-opaque mx-2" style={{borderRadius: '0.8rem'}}>
                                    <div className="d-flex flex-column h-100 px-3 pb-2 pt-4 text-white text-shadow-1">
                                        <Image style={{width: '50%', maxWidth: 100, maxHeight: 100}} className="mx-auto" src={Disagree}></Image>
                                        <h5 className="mt-4">Disagree</h5>
                                    </div>
                                    </div>
                                </div>
                                <div className="col my-2">
                                    <div data-value="Agree" onClick={this.onCardClick} className="card card-cover survey-card h-100 overflow-hidden text-white border-3 border-white-opaque mx-2" style={{borderRadius: '0.8rem'}}>
                                    <div className="d-flex flex-column h-100 px-3 pb-2 pt-4 text-white text-shadow-1">
                                        <Image style={{width: '50%', maxWidth: 100, maxHeight: 100}} className="mx-auto" src={Agree}></Image>
                                        <h5 className="mt-4">Agree</h5>
                                    </div>
                                    </div>
                                </div>
                                <div className="col my-2">
                                    <div data-value="Strongly Agree" onClick={this.onCardClick} className="card card-cover survey-card h-100 overflow-hidden text-white border-3 border-white-opaque mx-2" style={{borderRadius: '0.8rem'}}>
                                    <div className="d-flex flex-column h-100 px-3 pb-2 pt-4 text-white text-shadow-1">
                                        <Image style={{width: '50%', maxWidth: 100, maxHeight: 100}} className="mx-auto" src={StronglyAgree}></Image>
                                        <h5 className="mt-3">Strongly<br></br>Agree</h5>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Button 
                            onClick={AnimateStepChangeBackward}
                            size="lg"
                            className="my-4 px-5 fw-bold mx-1 text-secondary bg-white border-0 rounded-0"
                            variant="light"
                        >
                            <ChevronLeft></ChevronLeft> Back
                        </Button>
                        <Button 
                            onClick={this.onQuestionSubmit}
                            disabled={!this.state.activeButton}
                            size="lg"
                            className="my-4 px-5 fw-bold mx-1 text-dark-blue bg-white border-3"
                        >
                            Submit <ChevronRight className="mb-1"></ChevronRight>
                        </Button>
                    </div>
                </div>
            )
        }
    }

    //Redemption form fill
    const RedeemGift = () => {

        const formEl = useRef(null);
        const submitButton = useRef(null);

        const formSubmit = (event) => {
            event.preventDefault();
            submitButton.current.disabled = true;
            formEl.current.classList.add('was-validated');
            if(formEl.current.checkValidity()){
                let payload = {};

                //Grab all the form elements, spread into array, for each element append id and value to payload
                let formElements = formEl.current.elements;
                formElements = [...formElements];
                formElements.forEach(formElement => {
                    if (formElement.value) {
                        payload[formElement.id] = formElement.value;
                    }
                });
                //Add page unique ID to payload
                payload["uniqueId"] = props.uniqueId
        
                //Send to form processing endpoint
                axios.post('https://prod-159.westus.logic.azure.com:443/workflows/3e5e41a3a513474c8d8e4355cb3c8137/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=37KCt3Yj13SWaCPKI-MFSyC74ZBaICrEaDYB8JEfExY', payload)
                    //success
                    .then(() => {
                        console.log('success')
                        submitButton.current.classList.remove('text-dark-blue');
                        submitButton.current.classList.remove('bg-white');
                        submitButton.current.classList.remove('border-3');
                        submitButton.current.classList.add('btn-success');
                        submitButton.current.textContent = 'Success!';
                        submitButton.current.style.opacity = 1;
                    })
                    //error
                    .catch(error => {
                        if(error.response.status === 409){
                            submitButton.current.classList.remove('text-dark-blue');
                            submitButton.current.classList.remove('bg-white');
                            submitButton.current.classList.remove('border-3');
                            submitButton.current.classList.add('btn-danger');
                            submitButton.current.textContent = 'You have already submitted this form';
                            submitButton.current.style.opacity = 1;
                        }
                        else{
                            alert('There was an error processing your submission, please reload the page and try again');
                        }
                        submitButton.current.disabled = false;
                    })
            }
            else{
                submitButton.current.disabled = false;
            }
        }

        return(
            <div className="assessment-step" ref={CurrentStep}>
            <Row className="w-100 mx-auto">
                <Col xs={10} md={10} lg={10} className="pt-4 mx-auto">
                <h3 className="ms-2 mt-4 text-salesforce-blue SalesforceSans-Bold">
                        Thank you for submitting the assessment.
                    </h3>
                </Col>
            </Row>
            <Row className="w-100 mx-auto">
                <Col xs={10} md={10} lg={10} className="mx-auto">
                    <Row className="justify-content-center w-100 mx-auto">
                        <Col lg={6} className="mx-auto">
                            <Row className="justify-content-center justify-content-lg-end mt-4">
                                <Col className="text-center">
                                    <p className="text-start text-white SalesforceSans-Light fs-4">
                                    We love that you’re thinking about how a great experience can make your customers and your employees happier. Make sure to check out the EPAM PolSource <a className="text-white" href="https://polsource.com/10-keys-to-success/?utm-source=polSource&utm-medium=email&utm-campaign=salesforce-door-opener" target="blank">10 Keys to Success</a> to help you transform business processes and improve the experiences your company delivers!
                                    </p>
                                    <Image 
                                        style={{maxHeight:300, maxWidth:375}} 
                                        className="img-fluid mx-auto" 
                                        src={GiftImage}
                                    ></Image>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={6}>
                        <Row className="justify-content-center mt-4 justify-content-lg-start">
                            <Col className="text-start">
                                <p className="text-white SalesforceSans-Light fs-4">
                                    Now, it’s time for us to spread the happiness.
                                </p>
                                <p className="text-white SalesforceSans-Light fs-4">
                                    Where can we send your free gift?
                                </p>
                                <Form ref={formEl} onSubmit={formSubmit}  className="needs-validation" noValidate>
                                    <Row>
                                        <Col>  
                                            <div className="my-2">
                                                <input type="text" className="form-control form-input-pill" maxLength="256" id="firstName" placeholder="First Name" required />
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="my-2">
                                                <input type="text" className="form-control form-input-pill" maxLength="256" id="lastName" placeholder="Last Name " required />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="pb-0">
                                        <Col xs={8}>  
                                            <div className="mt-2">
                                                <input type="text" className="form-control form-input-pill" maxLength="70" id="address" placeholder="Street Address" required />
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="mt-2">
                                                <input type="text" className="form-control form-input-pill" maxLength="70" id="address2" placeholder="Apt/Suite" />
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="my-3">
                                        <input type="text" className="form-control form-input-pill" maxLength="70" placeholder="City" id="city" required />
                                    </div>
                                    <div className="my-3">
                                        <input className="form-control form-input-pill" maxLength="70" name="state" id="state" placeholder="State" required />
                                    </div>
                                    <div className="my-3">
                                        <input type="text" maxLength="70" className="form-control form-input-pill" id="postalCode" placeholder="Postal Code" required />
                                    </div>
                                    <div className="my-3">
                                        <input type="text" maxLength="70" className="form-control form-input-pill" id="country" placeholder="Country" required />
                                    </div>
                                    <div className="my-3">
                                        <input type="text" maxLength="50" className="form-control form-input-pill" pattern="^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$" id="phone" placeholder="Phone Number (optional)" />
                                    </div>
                                    <Col className="text-center">
                                        <Button
                                            type="submit"
                                            size="lg"
                                            className="my-2 w-100 mx-auto text-dark-blue bg-white border-3"
                                            ref={submitButton}
                                        >
                                            Submit To Receive Your Free Gift
                                        </Button>
                                        <p style={{opacity: '0.85'}} className="text-white">
                                            <small>
                                            By submitting your contact information you confirm that you would like to receive a gift from Salesforce subject to the offer terms below. Your information may be shared with Salesforce, along with program sponsor EPAM Polsource, and used as described in Salesforce's Privacy Statement. 
                                            </small>
                                        </p>
                                    </Col>
                                </Form>
                            </Col>
                        </Row>
                    </Col>
                    </Row>
                </Col>
            </Row>
        </div>
        )
    }

    //Use anime js to animate changes forward/back
    const AnimateStepChangeForward = () => {
       anime({
            easing: 'easeOutExpo',
            duration: 660,
            targets: CurrentStep.current,
            opacity: 0,
            complete: function(){
                setStepState(stepState + 1)
                anime({
                    duration: 660,
                    targets: CurrentStep.current,
                    opacity: 1
                })
            }
          });
    }
    const AnimateStepChangeBackward = () => {
        anime({
             easing: 'easeOutExpo',
             duration: 660,
             targets: CurrentStep.current,
             opacity: 0,
             complete: function(){
                 setStepState(stepState - 1)
                 anime({
                     duration: 660,
                     targets: CurrentStep.current,
                     opacity: 1
                 })
             }
           });
    }

    
    return(
        <>
            <Container className="step-container pb-3 mt-4">
                {StepController()}
            </Container>  
        </>
    )
}